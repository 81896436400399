.pagadiario-container {
    @apply w-full h-[144px];
    @apply h-[300px] md:h-[350px] lg:h-[400px];
    @apply bg-no-repeat bg-cover bg-right;
  }
  .card-home-info h3 {
    @apply bottom-0 text-primary-800 bg-primary-50 w-full text-center py-4 font-semibold text-[16px] flex items-center justify-center;
  }

  .o_card2_title_home h6 {
    @apply text-center font-light sm:text-2xl md:text-3xl text-primary-800 mt-10;
  }