@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-primary-50;
  font-family: "Montserrat", sans-serif;
}
