.o_footer_container {
  @apply w-full h-16 bg-primary-50 sticky z-50 top-0;
}

.o_navbar_container {
  @apply w-full h-full max-w-7xl mx-auto px-0 lg:px-10 flex items-center justify-between px-4;
}

.o_navbar_container_a {
  @apply h-full items-center hidden md:flex;
}

.o_navbar_container_header {
  @apply uppercase h-full text-black px-6 font-bold text-[12px];
}

.o_productos {
  @apply uppercase h-full text-black px-6 font-bold text-[12px] cursor-pointer;
}

.o_navbar_container_a__lb {
  @apply h-full grid content-center text-primary-800;
}
.o_navbar_container_a__lb:hover{
  @apply scale-125;
}
.o_nav_prod{
  @apply text-primary-800 ;
}
.o_nav_prod:hover{
  @apply bg-secondary-200;
}
.o_nav_prod div a{
  @apply bg-secondary-400;

}
.o_navbar__menu {
  @apply w-full absolute z-50 left-0 top-16 px-[10%] xl:px-[15%] pt-4 hidden;
}

.o_productos:hover + .o_navbar__menu,
.o_navbar__menu:hover {
  @apply block;
}

.navbar-menu {
  display: none;
}

.navbar-menu.active {
  display: block;
}

.navbar-items {
  list-style: none;
  margin-top: 5%;
  padding: 0;
  margin-left: 10px;
  display: inline-block;
}

.navbar-item {
  display: block;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  color:#02518c;
  font-weight: bold;
  margin-right: 1rem;
  cursor: pointer;
}

.navbar-subitem{
  display: block;
  color:#02518c;
  font-weight: 500;
  margin-right: 1rem;
  cursor: pointer;
}
.navbar_sub_submenu{
  color: #02518c;
  margin-left: 1rem;  
}


.navbar-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
}

.sub-menu {
  list-style: none;
  padding: 0;
  margin-left: 15px;
}

.sub-menu li {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.sub-menu li:hover {
  border-radius: 2rem;
  background-color: #fcb42e;
  font-weight: bold;
}

.navbar-item:hover{
  border-radius: 2rem;
  font-weight: bold;
  background-color: #fcb42e;
}