.container-card {
    @apply flex flex-col items-center h-full;
    @apply py-12 px-4;
    @apply rounded-tl-3xl rounded-br-3xl;
  }
  
  .container-card__icon {
    @apply flex flex-col items-center;
    @apply w-16 h-16 lg:w-20 lg:h-20 flex items-center justify-center;
  }
  
  .container-card h1 {
    @apply mt-2 font-semibold text-[14px] lg:text-[16px] leading-none text-center;
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    justify-items: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    margin-bottom: 100px;
    padding-bottom: 60%;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    width: 350px;
    --tw-bg-opacity: 1;
    background-color: rgb(239 248 255 / var(--tw-bg-opacity));
    color: black;
  }
  
  /* Style the back side */
  .flip-card-back {
    width: 350px;
    display: flex;
    flex-direction: column;
    text-align: justify;
    padding: 2rem;
    transform: rotateY(180deg);
  }
  