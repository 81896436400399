.digital-container {
  @apply w-full h-[144px];
  @apply h-[300px] md:h-[350px] lg:h-[400px];
  @apply bg-no-repeat bg-cover bg-right bg-primary-800;
}
.digital-container {
  @apply bg-[url('/public/images/credito_digital_movil.jpg')];
}

@media screen and (min-width: 768px) {
  .digital-container {
    @apply bg-[url('/public/images/credito_digital_tablet.jpg')];
  }
}

@media screen and (min-width: 1024px) {
  .digital-container {
    @apply bg-[url('/public/images/credito_digital.jpg')];
  }
}

/*PARA CRÉDITO PAGO DIARIO*/

.pagodiario-container {
  @apply w-full h-[144px];
  @apply h-[300px] md:h-[350px] lg:h-[400px];
  @apply bg-no-repeat bg-cover bg-right bg-primary-800;
}
.pagodiario-container {
  @apply bg-[url('/public/images/pagodiario_new_movil.jpg')];
}

@media screen and (min-width: 768px) {
  .pagodiario-container {
    @apply bg-[url('/public/images/pagodiario_new_tablet.jpg')];
  }
}

@media screen and (min-width: 1024px) {
  .pagodiario-container {
    @apply bg-[url('/public/images/pagodiario_new.jpg')];
  }
}

/*PARA CREDITO MÁS INCLUSIVO*/
.masinclusivo-container {
  @apply w-full h-[144px];
  @apply h-[300px] md:h-[350px] lg:h-[400px];
  @apply bg-no-repeat bg-cover bg-right bg-primary-800;
}
.masinclusivo-container {
  @apply bg-[url('/public/images/mas_inclusivo_movil.jpg')];
}

@media screen and (min-width: 768px) {
  .masinclusivo-container {
    @apply bg-[url('/public/images/mas_inclusivo_tablet.jpg')];
  }
}

@media screen and (min-width: 1024px) {
  .masinclusivo-container {
    @apply bg-[url('/public/images/mas_inclusivo.jpg')];
  }
}

/*PARA CREDITO RAPIDIN*/
.rapidin-container {
  @apply w-full h-[144px];
  @apply h-[300px] md:h-[350px] lg:h-[400px];
  @apply bg-no-repeat bg-cover bg-right bg-primary-800;
}
.rapidin-container {
  @apply bg-[url('/public/images/rapidin_movil.jpg')];
}

@media screen and (min-width: 768px) {
  .rapidin-container {
    @apply bg-[url('/public/images/rapidin_tablet.jpg')];
  }
}

@media screen and (min-width: 1024px) {
  .rapidin-container {
    @apply bg-[url('/public/images/rapidin.jpg')];
  }
}

/*PARA AHORRO PLAZO FIJO*/

.fijo-container {
  @apply w-full h-[144px];
  @apply h-[300px] md:h-[350px] lg:h-[400px];
  @apply bg-no-repeat bg-cover bg-right bg-primary-800;
}
.fijo-container {
  @apply bg-[url('/public/images/flazo-fijo_movil.jpg')];
}

@media screen and (min-width: 768px) {
  .fijo-container {
    @apply bg-[url('/public/images/flazo-fijo_tablet.jpg')];
  }
}

@media screen and (min-width: 1024px) {
  .fijo-container {
    @apply bg-[url('/public/images/flazo-fijo.jpg')];
  }
}

/*PARA AHORRO CUENTA ESTRELLA*/

.estrella-container {
  @apply w-full h-[144px];
  @apply h-[300px] md:h-[350px] lg:h-[400px];
  @apply bg-no-repeat bg-cover bg-right bg-primary-800;
}
.estrella-container {
  @apply bg-[url('/public/images/cuenta_movil.jpg')];
}

@media screen and (min-width: 768px) {
  .estrella-container {
    @apply bg-[url('/public/images/cuenta_movil.jpg')];
  }
}

@media screen and (min-width: 1024px) {
  .estrella-container {
    @apply bg-[url('/public/images/cuenta_movil.jpg')];
  }
}